import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

const axios = require('axios');

class ButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadyStarted: false,
      buttonText: "Redeploy"
    };
  }

  click() {
    this.setState({ alreadyStarted: true, buttonText: "Redeployed" });

    axios({
        method: "post",
        url: "https://api.netlify.com/build_hooks/5d1dd2ee8982999b2d25ed81",
        headers: {'ContentType': 'application/json'},
        data: {}
    }).then(() => console.log("Success!")).catch(() => console.log("Error!"));
  }

  render() {
    return (
      <div style={{ padding: "20px 0px 20px 0px" }}>
        <button onClick={this.click.bind(this)} disabled={this.state.alreadyStarted}>{ this.state.buttonText }</button>
      </div>
    );
  }
}

export default () => {
  return (
    <Layout>
      <div
        style={{
          backgroundColor: "#E1E1E1",
          padding: "20px",
          margin: "10px",
          borderStyle: "solid",
          borderWidth: "0px"
        }}
      >
        <h2
          dangerouslySetInnerHTML={{ __html: "Redeploy carllundin.se" }}
          style={{ margin: "0px 0px 10px 0px" }}
        />
        <ButtonComponent></ButtonComponent>
        <Link to={"/"}>Back home</Link>
      </div>
    </Layout>
  );
};
