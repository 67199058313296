import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet"

import Header from "./header";
import Footer from "./footer";

import "./layout.css";
import "../styles/_variables.scss";
import "../styles/main.scss";
import "react-netlify-identity-widget/styles.css" // delete if you want to bring your own CSS

const Layout = ({ children }) => (  
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
      <div className="site" style={{ backgroundColor: "$background-color" }}>      
        <Helmet>
          <meta charSet="utf-8" />
          <title>carllundin.se</title>
        </Helmet>  
        <Header siteTitle={data.site.siteMetadata.title} />
        <div  
          className="site-content"        
          style={{
            margin: '0 auto',
            width: '100%',
            maxWidth: 960,
            padding: '0px',
            paddingTop: 0
          }}
          >
            <main>{children}</main>
          </div>              
        <Footer siteTitle={data.site.siteMetadata.title} />
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
